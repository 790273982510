.content-container{
  margin: 0!important;
  padding: 0!important;
}
.row {
  margin: 0px !important;
}
.col {
  margin: 0px !important;
}
.main-col{
  margin: 0!important;
  padding: 0!important;
}


@font-face {
  font-family: 'SF UI Text Regular';
  font-style: normal;
  font-weight: normal;
  src: local('SF UI Text Regular'), url('./../src/fonts/SFUIText-Regular.woff') format('woff');
}
@font-face {
  font-family: 'tokyo_regularregular';
  src: url('./../src/fonts/tokyoregular-regular-webfont.eot');
  src: url('./../src/fonts/tokyoregular-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('./../src/fonts/tokyoregular-regular-webfont.woff2') format('woff2'),
  url('./../src/fonts/tokyoregular-regular-webfont.woff') format('woff'),
  url('./../src/fonts/tokyoregular-regular-webfont.ttf') format('truetype'),
  url('./../src/fonts/tokyoregular-regular-webfont.svg#tokyo_regularregular') format('svg');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'tokyo_mergedregular';
  src: url('./../src/fonts/tokyomerged-regular-webfont.eot');
  src: url('./../src/fonts/tokyomerged-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('./../src/fonts/tokyomerged-regular-webfont.woff2') format('woff2'),
  url('./../src/fonts/tokyomerged-regular-webfont.woff') format('woff'),
  url('./../src/fonts/tokyomerged-regular-webfont.ttf') format('truetype'),
  url('./../src/fonts/tokyomerged-regular-webfont.svg#tokyo_mergedregular') format('svg');
  font-weight: normal;
  font-style: normal;

}
@font-face {
  font-family: 'tokyo_outlineregular';
  src: url('./../src/fonts/tokyooutline-regular-webfont.eot');
  src: url('./../src/fonts/tokyooutline-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('./../src/fonts/tokyooutline-regular-webfont.woff2') format('woff2'),
  url('./../src/fonts/tokyooutline-regular-webfont.woff') format('woff'),
  url('./../src/fonts/tokyooutline-regular-webfont.ttf') format('truetype'),
  url('./../src/fonts/tokyooutline-regular-webfont.svg#tokyo_outlineregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'town-Medium';
  src: url('./../src/fonts/Jason-Vandenberg-Town80Text-Medium.eot');
  src: url('./../src/fonts/Jason-Vandenberg-Town80Text-Medium.eot?#iefix') format('embedded-opentype'),
  url('./../src/fonts/Jason-Vandenberg-Town80Text-Medium.woff2') format('woff2'),
  url('./../src/fonts/Jason-Vandenberg-Town80Text-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.css-tlfecz-indicatorContainer {
  color: #004860 !important;
}
.btn-primary {
  background-color: #004860 !important;
  border-color: #004860 !important;
  font-family: var(--main-font-family);

}



.rtl-direction .phone-verification-form1 .phone {
  border-radius: 0 8px 8px 0 !important;
}
.rtl-direction .phone-verification-form1 button {
  border-radius: 8px 0 0 8px !important;
}
.rtl-direction  .license-plate .plate-inputs,
.rtl-direction  .phone-verification-form2 .row {
  direction: ltr;
}
.rtl-direction .payment-options .payment-option .custom-control-label::before,
.rtl-direction .payment-options .payment-option .custom-control-label::after {
  right: inherit !important;
  left: 0 !important;
}
.rtl-direction .language > div {
  direction: ltr;
}
.rtl-direction .top-header > div:nth-child(2):before {
  margin-left: 10vw;
}
.rtl-direction .phone-verification-form2 .arrow::before {
  transform: translate(-50%, -50%) rotate(135deg);
  margin-left: 18px;
}
.rtl-direction .form-control {
  text-align: right;
}
.col {
  min-width: 0;
}



.input-group-text {
  background-color: #009688;
  border: 0;
}

.input-group-text span {
  color: #ffffff;
}

.App {
  text-align: center;
  background-color: #f1f2f6;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.modal-content {
  border: none !important;
}


@keyframes modalFade {
  from {transform: translateY(100%);opacity: 0;}
  to {transform: translateY(0%);opacity: 1;}
}

.modal {
  animation-name: modalFade;
  animation-duration: 0.7s;

}
.custom-dialog-xx{


  width: 100vw !important;
  position: fixed !important;
  bottom: 0 !important;

  left: 0 !important;
  right: 0 !important;

}
.modal-dialog{
  margin: 0 !important;
}


.spinner-loading{
  background-image:url('../src/assets/images/spinner.svg');
  background-position: 3px 11px;
  background-repeat: no-repeat;
  padding-left: 30px;
}
