.body{
    width: 100vw ;
}
.row {
    margin: 0px ;
    padding: 0;
}
.col {
    margin: 0px ;
    padding: 0;

}
.card-conatiner{
    margin-top: 0.5em !important;
}

.parking-image{
    max-width: 33vw;

    max-height: 10vh;
    width: auto;
}

.main-container{
    width: 100%;
    min-height: 100vh;
    background-color: #d9d9d9;
    z-index: -10;
}
.modal{
    width: 100%;
    position: fixed;

}

.line{
    color: #000;
    border: 1px solid #000;
    width: 100%;
    padding: 0!important;
    margin: 0!important;

}


.spinner-modal .modal-dialog .modal-content{
    border-radius: 2rem;
    background-color: #c0c0c0;
    text-align: center;
    margin: 0 auto;
    width: 100vw;
    right: 0;
    position: fixed;
    bottom: 0;
}





.modal-content{
    min-width: 0vw !important;

}
.modal-title{
    font-weight: var(--main-font-weight);
    font-size: 18px;
    padding-top: 10px;
    font-family: var(--main-font-family);
    text-transform: uppercase;
}
.modal .close{
    display: none;
}
.cover {
    background-color: #d9d9d9;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    max-height: 33vh;
    height: 33vh;
    position: static;
    z-index: -2;
    top: 0;
    width: 100%;
 }

.spinner {
    max-height: 33vh;
    width: 100%;
    height: 50vw;
    z-index: 10;
    background-color: transparent;

}
.spinner img {
    max-width: 300vw!important;

}

.spinner-message{
    font-family:  var(--main-font-family);
    font-size: 1.2rem;
    text-transform: uppercase;
}
.spinner .img {
    height: 50%;
     position: absolute !important;
    background-color: #d9d9d9;
}
.alert-danger{
    top: 16px;
    color: rgb(220, 53, 69);
    font-family:  var(--main-font-family);
    text-transform: uppercase;
}

.alert{
    margin-bottom: 1.2rem !important;
}
.ticket-information{
    min-height: 30vh;
    width: 100%;
    position: absolute;
    top: 14vh;
    z-index: 0;
}


.pay-message {
    color: #595959;
    font-family:  var(--main-font-family);
    font-size: 1rem;
    font-weight: var(--main-font-weight);
    text-align: center;
    display: flex;
    align-items: center;
     background-color: #d9d9d9;
     min-height: auto!important;
    margin: 0 !important;
    padding: 0.75em 0em!important;
    width: 97.5% !important;
}
.container-pay-message{
    position: absolute;
    top: 44vh;
    width: 100%;
    margin: 0;
    padding: 0 4rem;
    font-family:  var(--main-font-family);
    color: #595959;
    font-size: 1.3rem;
    text-align: center;

}

.container-options{
    text-align: center;
    position: absolute;
    top: 50vh;
    margin: 0 !important;
    padding: 1em 0em!important;
    width: 100% !important;

}

.language-btn{
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.5rem;
    width: 30vw;
    background-color: #fff !important;
    border: none ;
    color: #000000;
    border-radius: 1.2rem;
}


/* SMARTPHONES PORTRAIT */
@media only screen and (max-width: 320px) {


    .pay-message {
         font-size: 1rem;

     }
}


/*@media (prefers-color-scheme: dark) {*/
 /*.container-pay-message{*/
     /*color: white;*/
 /*}*/
/*}*/