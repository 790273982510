

body {
  background-color: #d9d9d9 !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
  
}

html[lang*=ar] {
  font-family: "Segoe UI Semilight" !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --main-font-family: 'Tahoma', sans-serif;
  --main-font-weight: var(--main-font-weight);
  color-scheme: light dark;

}

.banner {
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 10px;
  background-color: white;
  border-top: 1px solid #ddd;
}
