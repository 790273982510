input[name=payment-option] {
    display: none;
}

input[type=radio] {
    display: none
}

.option-container {
    background-color: #fff;
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
    border-radius: 1.5rem;
    font-family: var(--main-font-family);
 }

.option-container:hover {
    background-color: #75b593;
}


.payment-options .payment-option {
    background-color: #d9d9d9;
}

.payment-options .payment-option .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #004860;
    background-color: #004860;
}

.payment-options .payment-option .custom-control-label {
    cursor: pointer;
    width: 100%;
}

.payment-options .payment-option .custom-control-label::before, .payment-options .payment-option .custom-control-label::after {
    right: 0 !important;
    left: unset !important;
    width: 1.5em;
    height: 1.5em;
}

.payment-options .payment-option .custom-control-label > div > div {
    padding-left: 0 !important;
}

.payment-options .payment-option .custom-control-input {
    right: 0 !important;
    left: unset;
}

.payment-options .payment-option .custom-control {
    padding-left: 0 !important;
}

.payment-options .payment-option .row {
    padding-bottom: 0.4rem;
    padding-top: 0.4rem;
}

.payment-options .payment-option .row img {
    max-height: 22px;
    max-width: 30vw;
}

.payment-options .payment-option p {
    font-weight: 600;
    color: #454a50;
    margin-bottom: 0;
}

.payment-options .pay {
    border-radius: 4em;
    background-color: #004860;
}

.payment-options .title {
    font-weight: 400;
    padding-top: 20px;
    color: #595959;
    font-family: var(--main-font-family);
    font-size: 1.2rem;
    margin-bottom: 0;

}

.payment-options .form-control {
    font-size: 16px !important;
    border-radius: 8px !important;
    border: 1px solid #6a6cb1;
}

.payment-options .pay {
    border-radius: 4em;
    background-color: #004860 !important;
}

.payment-options .payment-option .custom-control-label::before, .payment-options .payment-option .custom-control-label::after {
    right: 0 !important;
    left: unset !important;
    width: 1.5em;
    display: contents;
    height: 1.5em;
    display: contents !important;
}

.is-loading {
    z-index: 100;
    background: #F0F2F5;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}

.is-loading .div {
    display: none;
}

.error {
    font-size: 80%;
    color: rgb(220, 53, 69);
    margin-top: 0.5rem;
    font-family: var(--main-font-family);
    text-transform: uppercase;
}

.custom-dialog {
    width: 90% !important;
    background-color: #ffffff;
    bottom: 0;
}

.success-modal .modal-dialog .modal-content {
    width: 100vw;
    right: 0;
    position: fixed;
    bottom: 0;
    border-radius: 2rem;
    background-color: #c0c0c0;
    text-align: center;
}

.confirmation-modal .modal-dialog .modal-content {
    right: 0;
    position: fixed;
    bottom: 0;
    border-radius: 2rem;
    background-color: #c0c0c0;
    text-align: center;
    width: 100vw;

}

.confirmation-modal .modal-dialog .modal-content .modal-footer .confirm-btn {
    color: #fff;
    background-color: #57a47a !important;
    border-color: #57a47a !important;
    padding: 0.7rem 1.5rem;
    font-size: 1rem;
    margin-left: 5vw;
    margin-right: 5vw;

}

.confirmation-modal .modal-dialog .modal-content .modal-footer .cancel-btn {
    color: #fff;
    background-color: #c65655 !important;
    border-color: #c65655 !important;
    font-size: 1rem;
    padding: 0.7rem 1.5rem;
    margin-left: 5vw;
    margin-right: 5vw;
}

.selectedOptionImg {
    max-width: 55vw !important;
    height: auto;
}

/*@media only screen and (max-width: 320px) {*/

    /*.payment-options .title {*/
        /*font-size: 0.7em;*/
        /*padding-top: 14px;*/
    /*}*/

/*}*/

/*@media (prefers-color-scheme: dark) {*/
    /*.option-container {*/
        /*background-color: #000000 !important;*/

    /*}*/
    /*.payment-options .payment-option p {*/
        /*color: white;*/

    /*}*/
    /*.title{*/
        /*color: white !important;*/

    /*}*/
/*}*/