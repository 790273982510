.main-container {
  height: 100vh;
}

.barcodeHeader {
  max-height: 30%;
  position: static;
.barcode-title {
  font-family: var(--main-font-family);
  font-weight: 600;
  font-size: 6vw;
  padding-top: 2vw;
  line-height: 1.5;
  color: #212529;
  text-align: center;
}

.barcode-sub-title {
  font-family: var(--main-font-family);
  font-weight: 600;
  font-size: 5vw;
  line-height: 1.5;
  color: #212529;
  text-align: center;
}
}

.barcodeBody {
  margin-top: 1rem;
.webcamCapture {
  position: relative;
  width: 100%;
  height: 50vh;
}
.captureImg {
  position: relative;
  width: 100%;
  height: 50vh;
}
}

#video-stream {
video {
  width: 100%;
}
img {
  width: 100%;
}
}

#uploadImage {
  visibility: hidden;
  height: 1px !important;
}

button {
  background-color: #004860;
  border-color: #004860;
  z-index: 0 !important;
  text-transform: uppercase;
}

@media only screen and (max-device-width: 320px) {
  .webcamCapture {
    position: fixed;
    height: 100%;
    width: 100%;
  }
  .captureImg {
    position: fixed;
    height: 100%;
    width: 100%;
  }
}

@media only screen and (max-device-width: 640px) {
  .webcamCapture {
    position: fixed;
    height: 100%;
    width: 100%;
  }

}

@media only screen and (max-device-width: 768px) {
  .webcamCapture {
    position: fixed;
    height: 100%;
    width: 100%;
  }
}

#spinner-1 #spinner-2 {
  border-radius: 0 8px 8px 0 !important;
  background-color: #004860;
  border-color: #004860;
  z-index: 0 !important;
}

.spinner-loading {
  border-radius: 5px;
  background-image: url('./../../assets/images/spinner.svg');
  background-position: 3px 11px;
  background-repeat: no-repeat;
  padding-left: 30px;
}

@media (min-width: 801px) {
  .barcodeHeader {
    max-height: 20%;
  .barcode-title {
    font-size: 1.2rem;
  }
  .barcode-sub-title {
    font-size: 1rem;
  }
}

#video-stream {
  text-align: center;

video {
  width: 45%;
}
img {
  width: 45%;
}
}

}


