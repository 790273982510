.main {
    text-align: center;
}

.card {
    min-height: 13vh;
    width: 95%;
    margin: auto;
    border-radius: 1.2rem;
    background: #d9d9d9;
    background-blend-mode: normal;
    box-shadow: 6px 5px 7px 0px rgba(89, 89, 89, 0.76);
}

.row {
    margin: 0px;
    padding: 0;
}

.col {
    margin: 0px;
    padding: 0;

}

.card-container {
    margin-top: 0.5em !important;
}

.card-body {
    width: 100%;
    height: 100%;
    padding: 0.75rem;
    border-radius: 8px;
    background: #fff;
    background-blend-mode: normal;
    box-shadow: none;
}

.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

}

.inner-top {
    height: 40%;

    width: 100%;
    text-align: center;
}

.inner-bottom {
    height: 60%;
    width: 100%;
    text-align: center;
}
.amount-promotion  {
    margin-top: -3px;
    padding: 0;
    font-size: 0.9rem;
    font-family: var(--main-font-family);;
    color: #00b050;

}

.amount-text {
    color: #00b050;
    font-weight: 700;
    text-align: center;
    width: -moz-available;
    width: -webkit-fill-available;
    font-family: var(--main-font-family);;
    text-transform: uppercase;
    font-size: 1.8rem;

}

.duration-title {
    position: relative;
    color: #595959;
    font-family: var(--main-font-family);;
    font-size: 1.3rem;
    font-weight: var(--main-font-weight);
    text-align: center;
    width: -moz-available;
    width: -webkit-fill-available;

}

.duration-text {
    position: relative;
    color: #000;
    font-family: var(--main-font-family);;
    font-size: 1.3rem;
    font-weight: 500;
    width: -moz-available;
    width: -webkit-fill-available;

}

.entry-date-title {
    position: relative;
    color: #595959;
    font-family: var(--main-font-family);;
    font-size: 1.3rem;
    font-weight: var(--main-font-weight);
    width: -moz-available;
    width: -webkit-fill-available;

}

.entry-date-text {
    position: relative;
    color: #000;
    font-family: var(--main-font-family);
    font-size: 1.3rem;
    font-weight: var(--main-font-weight);
    width: -moz-available;
    width: -webkit-fill-available;

}

.ticket-number-title {
    position: relative;
    color: #595959;
    font-family: var(--main-font-family);
    font-size: 1.3rem;

    font-weight: var(--main-font-weight);
    width: -moz-available;
    width: -webkit-fill-available;

}

.ticket-number-text {
    line-break: anywhere;
    position: relative;
    color: #000;
    font-family: var(--main-font-family);
    font-size: 1.3rem;
    font-weight: var(--main-font-weight);
    width: -moz-available;
    width: -webkit-fill-available;

}

.card-title {
    color: #595959;
    font-family: var(--main-font-family);
    font-size: 1.3rem;
    font-weight: var(--main-font-weight);
    display: flex;
    align-items: center !important;
    padding: 1em 0 0 0;
    text-align: center;
    width: -moz-available;
    width: -webkit-fill-available;

}

.card-body {
    color: #000000;
    font-family: var(--main-font-family);
    font-size: 1.3rem;
    font-weight: var(--main-font-weight);
    display: flex;
    align-items: center;
    position: absolute;
    width: -moz-available;
    width: -webkit-fill-available;

}

.is-loading {
    z-index: 100;
    background: #F0F2F5;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}

.is-loading .card-body {
    display: none;
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

.animated-background {
    animation-duration: 5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #d9d9d9;
    background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
    background-size: 800px 104px;
    height: 70px;
    position: relative;
}

.background-masker {
    background-color: #d9d9d9;
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -800px 0
    }
    100% {
        background-position: 800px 0
    }
}

/* SMARTPHONES PORTRAIT */
@media only screen and (max-width: 480px) {

    .amount-text {
        font-size: 1.3rem;
    }

    .duration-title {

        font-size: 1.5rem;
    }

    .duration-text {
        font-size: 1.3rem;

    }

    .entry-date-title {
        font-size: 1.5rem;

    }

    .entry-date-text {
        font-size: 1.3rem;

    }

    .ticket-number-title {
        font-size: 1.5rem;

    }

    .ticket-number-text {
        font-size: 1.3rem;
    }
}

/* SMARTPHONES PORTRAIT */
@media only screen and (max-width: 450px) {

    .amount-text {
        font-size: 1.1rem;
    }

    .duration-title {

        font-size: 1.1rem;
    }

    .duration-text {
        font-size: 1rem;

    }

    .entry-date-title {
        font-size: 1.1rem;

    }

    .entry-date-text {
        font-size: 1.1rem;

    }

    .ticket-number-title {
        font-size: 1.1rem;

    }

    .ticket-number-text {
        font-size: 1.1rem;
    }
}

/* SMARTPHONES PORTRAIT */
@media only screen and (max-width: 400px) {

    .amount-text {
        font-size: 1.1rem;
    }

    .duration-title {

        font-size: 1.2rem;
    }

    .duration-text {
        font-size: 0.9rem;

    }

    .entry-date-title {
        font-size: 1.2rem;

    }

    .entry-date-text {
        font-size: 0.9rem;

    }

    .ticket-number-title {
        font-size: 1.2rem;

    }

    .ticket-number-text {
        font-size: 0.8rem;
    }
}

/* SMARTPHONES PORTRAIT */
@media only screen and (max-width: 320px) {

    .amount-text {
        font-size: clamp(0.5rem, 10vw, 1rem);
    }

    .duration-title {

        font-size: 0.6rem;
    }

    .duration-text {
        font-size: 0.8rem;

    }

    .entry-date-title {
        font-size: 0.6rem;

    }

    .entry-date-text {
        font-size: 0.8rem;

    }

    .ticket-number-title {
        font-size: 0.6rem;

    }

    .ticket-number-text {
        font-size: 0.8rem;
    }
}

/*@media (prefers-color-scheme: dark) {*/
    /*.duration-text {*/
        /*color: white;*/
    /*}*/

    /*.entry-date-text {*/
        /*color: white;*/
    /*}*/

    /*.ticket-number-text {*/
        /*color: white;*/
    /*}*/
    /*.card-body {*/
         /*background-color: #000000;*/

     /*}*/
/*}*/