.success-page {
  .title {
    font-weight: 600;
    font-size: 26px;
    color: #57a47a;
    text-align: center;
    line-height: 1.2;
  }
  .first-section {
    text-align: center;
  }
  .success-img {
    width: auto;
    height: 140px;
    margin: 0 auto;
  }
  .form-submit {
    direction: ltr;
  }

  .btn-primary {
    background-color: #57a47a !important;
    border-color: #57a47a !important;
    font-family:  var(--main-font-family);

  }

  .country {
    border: 1px solid #57a47a;
    border-radius: 6px 0px 0px 6px !important;
    border-right: none;
    width: 25%;
    .css-yk16xz-control, .css-1pahdxg-control {
      border: none;
      height: 100%;

      .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
        padding-left: 0px;
      }
    }
  }

  .phone {
    border-radius: 0px !important;

  }

  #spinner {
    border-radius: 8px 8px 8px 8px !important;
    background-color: #57a47a;
    border-color: #57a47a;
    z-index: 0 !important;
  }

  .col-input-succes .css-1wa3eu0-placeholder {
    font-size: 12px;
  }

}

.disable-btn{
  cursor: not-allowed;
  border: none;
  background-color: rgb(229, 229, 229) !important;
}
.payment-try-again-btn{
  margin-bottom: 1rem!important;
}


.spinner-loading{
  background-image:url('../../assets/images/spinner.svg');
  background-position: 3px 11px;
  background-repeat: no-repeat;
  padding-left: 30px;
}

@media (max-width: 400px) {

  .col-input-succes {
    .country {
      width: 35%;
    }
    .input-group-append {
      width: 100%;
      margin-top: 10px;
    }
    #spinner {
      border-radius: 8px !important;
      width: 100%;
    }
  }
}

@font-face {
  font-family: 'SF UI Text Regular';
  font-style: normal;
  font-weight: normal;
  src: local('SF UI Text Regular'), url('../../fonts/SFUIText-Regular.woff') format('woff');
}

@font-face {
  font-family: 'tokyo_regularregular';
  src: url('../../fonts/tokyoregular-regular-webfont.eot');
  src: url('../../fonts/tokyoregular-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/tokyoregular-regular-webfont.woff2') format('woff2'),
  url('../../fonts/tokyoregular-regular-webfont.woff') format('woff'),
  url('../../fonts/tokyoregular-regular-webfont.ttf') format('truetype'),
  url('../../fonts/tokyoregular-regular-webfont.svg#tokyo_regularregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'tokyo_mergedregular';
  src: url('../../fonts/tokyomerged-regular-webfont.eot');
  src: url('../../fonts/tokyomerged-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/tokyomerged-regular-webfont.woff2') format('woff2'),
  url('../../fonts/tokyomerged-regular-webfont.woff') format('woff'),
  url('../../fonts/tokyomerged-regular-webfont.ttf') format('truetype'),
  url('../../fonts/tokyomerged-regular-webfont.svg#tokyo_mergedregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'tokyo_outlineregular';
  src: url('../../fonts/tokyooutline-regular-webfont.eot');
  src: url('../../fonts/tokyooutline-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/tokyooutline-regular-webfont.woff2') format('woff2'),
  url('../../fonts/tokyooutline-regular-webfont.woff') format('woff'),
  url('../../fonts/tokyooutline-regular-webfont.ttf') format('truetype'),
  url('../../fonts/tokyooutline-regular-webfont.svg#tokyo_outlineregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'town-Medium';
  src: url('../../fonts/Jason-Vandenberg-Town80Text-Medium.eot');
  src: url('../../fonts/Jason-Vandenberg-Town80Text-Medium.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Jason-Vandenberg-Town80Text-Medium.woff2') format('woff2'),
  url('../../fonts/Jason-Vandenberg-Town80Text-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.top-header > div:nth-child(1) {
  display: none;
}

body {
  font-family:  var(--main-font-family);
  background-color: #fbfbfb;
  margin: 0;
  max-width: 100%;
  overflow-x: hidden;
  text-transform: none;
  font-size: 1.2rem;
}

.select2 {
  width: 100%;
}

.address {
  font-size: 14px;
  margin: 0;
  color: #ffffff;
}

.form-control:hover,
.form-control:focus,
.btn:hover,
.btn:focus,
.select2 > div:hover,
.select2 > div:focus {
  box-shadow: none !important;
  outline: 0;
}

.form-control {
  font-size: 16px !important;
  border-radius: 8px !important;
  border: 1px solid #57a47a;
}

.select2 > div:first-child,
.form-control,
.select2,
.select2 > span + div {
  height: 50px !important;
}

.select2 > div,
.form-control:focus,
.select2 > div:hover,
.select2 > div:focus {
  border: 1px solid #57a47a;
}

.input-group-text {
  background-color: #57a47a;
  border: 0;
}

.input-group-text span {
  color: #ffffff;
}

#validationFormikPhone,
#validationFormikCode {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.code-input,
#validationFormikCode.registration-number {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.plate-inputs {
  margin-top: 20px;
  margin-bottom: 20px;
}

.form-row .rounded {
  margin: auto;
  max-width: 400px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.bg-green {
  background: #57a47a;
  padding: 40px 20px;
}


.send-sms-container{
  display: flex;
  justify-content: space-between;
}
.sms-counter{
  margin-right: 5px !important;
}
/* .header-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
} */

/* .image-wrapper {
    margin-right: 20px;
} */

.justify-content-md-center .col-sm-12 .row {
  margin: 0;
}

.bg-green + .col-md-12 {
  padding: 0;
}

.alert-warning {
  background-color: #f9f9f9;
  border-color: #f9f9f9;
  color: #867575;
}

.alert-success {
  background-color: #eef5f4;
  border-color: #eef5f4;
  color: #57a47a;
}

.alert-secondary {
  background-color: #eef5f4;
  border-color: #eef5f4;
  color: #867575;
}

@media (max-width: 767px) {
  .title {
    font-size: 32px;
  }
}

@media (max-width: 480px) {
  /* .image-wrapper img {
      width: 50px;
  } */
  /* .image-wrapper {
      margin-right: 15px;
  } */
  .bg-green {
    padding: 30px 15px;
  }
  .title {
    font-size: 26px;
  }
}